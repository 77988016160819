export default {
  id: 'auth-sign-in',
  context: {
    email: '',
    password: '',
    serviceError: {
      code: '',
      message: '',
    },
  },
  initial: 'dataEntry',
  states: {
    dataEntry: {
      on: {
        EMAIL_INPUT: {
          actions: 'saveEmail',
        },
        PASSWORD_INPUT: {
          actions: 'savePassword',
        },
        EMAIL_BLUR: [{
          cond: 'emailIsEmpty',
          target: 'emailError.isEmpty',
        }, {
          cond: 'emailFormatIsInvalid',
          target: 'emailError.invalidFormat',
        }, {
          target: 'awaitingAvailableProviders',
        }],
        PASSWORD_BLUR: [{
          cond: 'emailIsEmpty',
          target: 'dataEntry',
        }, {
          cond: 'passwordIsEmpty',
          target: 'passwordError.isEmpty',
        }, {
          cond: 'passwordTooShort',
          target: 'passwordError.tooShort',
        }],
        SUBMIT: [{
          cond: 'emailIsEmpty',
          target: 'emailError.isEmpty',
        }, {
          cond: 'emailFormatIsInvalid',
          target: 'emailError.invalidFormat',
        }, {
          cond: 'passwordTooShort',
          target: 'passwordError.tooShort',
        }, {
          target: 'awaitingSignInResponse',
        }],
      },
    },
    awaitingAvailableProviders: {
      invoke: {
        src: 'fetchSignInMethodsForEmail',
        onDone: [{
          cond: 'providerIsAvailable',
          target: 'dataEntry',
        }, {
          cond: 'providerIsNotAvailable',
          target: 'emailError.accountNotFound',
        }],
        onError: [{
          cond: 'serviceErrorInvalidEmailFormat',
          target: 'emailError.invalidFormat',
        }, {
          actions: 'saveServiceError',
          target: 'serviceError',
        }],
      },
    },
    awaitingSignInResponse: {
      // Make a call to the authentication service
      invoke: {
        src: 'signInWithEmailPassword',
        // If successful, move to the signedIn state
        onDone: {
          target: 'signedIn',
        },
        onError: [{
          cond: 'serviceErrorInvalidEmailFormat',
          target: 'emailError.invalidFormat',
        }, {
          cond: 'serviceErrorAccountNotFound',
          actions: 'resetPassword',
          target: 'emailError.accountNotFound',
        }, {
          cond: 'serviceErrorWrongPassword',
          actions: 'resetPassword',
          target: 'passwordError.wrongPassword',
        }, {
          actions: ['resetPassword', 'saveServiceError'],
          target: 'serviceError',
        }],
      },
    },
    emailError: {
      on: {
        EMAIL_INPUT: {
          actions: 'saveEmail',
          target: 'dataEntry',
        },
      },
      initial: 'invalidFormat',
      states: {
        isEmpty: {},
        invalidFormat: {},
        accountNotFound: {},
      },
    },
    passwordError: {
      on: {
        PASSWORD_INPUT: {
          actions: 'savePassword',
          target: 'dataEntry',
        },
      },
      initial: 'isEmpty',
      states: {
        isEmpty: {},
        tooShort: {},
        wrongPassword: {},
      },
    },
    serviceError: {
      on: {
        EMAIL_INPUT: {
          actions: 'saveEmail',
          target: 'dataEntry',
        },
        PASSWORD_INPUT: {
          actions: 'savePassword',
          target: 'dataEntry',
        },
        SUBMIT: {
          target: 'awaitingSignInResponse',
        },
      },
    },
    signedIn: {
      type: 'final',
    },
  },
};
