<template>
  <div>
    <AuthForm :machine-config="signInStateMachineConfig" type="sign-in" class="max-w-430 mt-20"
      @successful-submit="$router.push({ name: 'Orders' })">
      {{ $t('signin.login_to_start') }}
      <template #button-primary>
        <IconDoorEnter class="icon-door-enter h-22 w-22 mr-10" />
        {{ $t('signin.login') }}
      </template>
      <template #button-secondary>
        {{ $t('signin.forgot_password') }}
      </template>
    </AuthForm>
    <div class="mt-10" v-if="getCurrentLocale() === 'de'">
      <h4>Pour une version française, <a class="underline link" href="/fr/sign-in">veuillez cliquer ici.</a></h4>
    </div>
    <div v-else class="mt-10">
      <h4>Suchen Sie eine deutsche Version? <a class="underline link" href="/de/sign-in"> Klicken Sie hier.</a></h4>
    </div>

    <div>
      <button class="mt-12 button button-primary button-filled w-full flex items-center justify-center h-42
          tablet:w-auto tablet:px-35" @mousedown.prevent>
        <a target="_blank" href="https://www.galvaswiss.ch/kontakt">{{ $t('signin.register') }} </a>
      </button>
    </div>
  </div>
</template>


<script>
import IconDoorEnter from '@/components/icon/IconDoorEnter';
import AuthForm from '@/components/AuthForm';
import signInMachine from '@/machines/signInMachine';


export default {
  components: {
    IconDoorEnter,
    AuthForm,
  },
  data: () => ({
    signInStateMachineConfig: Object.freeze(signInMachine),
  }),
  methods: {
    getCurrentLocale() {
      return this.$i18n.locale;
    },
  },
};
</script>


<style></style>
